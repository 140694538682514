import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '../../../ui/forms/fields/input';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const Step3ClientDetails = ({ onNext, onBack, onReset }) => {
  const theme = useTheme();

  // Validation schema for form fields
  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Please enter the first name.'),
    lastName: yup.string().required('Please enter the last name.'),
    consultantName: yup.string().required('Please enter the consultant name.'),
    clientBackground: yup.string().required('Please enter client background.'),
    additionalComments: yup.string().nullable()
  });

  // Hook for managing form state
  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      consultantName: '',
      clientBackground: '',
      additionalComments: ''
    }
  });

  const fundFilterData = useSelector((state) => state.fundFilter);

  const onSubmit = (data) => {
    const dataToSubmit = { ...data, ...fundFilterData };
    onNext(dataToSubmit);
  };

  const NavButtons = () => (
    <Box
      sx={{
        position: 'sticky',
        bottom: 60,
        display: 'flex',
        alignItems: 'center',
        m: 2,
        mb: 4,
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        zIndex: 1000
      }}>
      <Button onClick={onBack}>Back</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button onClick={onReset}>Start Over</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button
        onClick={handleSubmit(onSubmit)}
        sx={{
          mr: 1,
          '&.Mui-disabled': {
            background: '#CC6323',
            color: '#fff'
          }
        }}>
        Next
      </Button>
    </Box>
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6">Complete the Client Details</Typography>

      <Input
        {...register('firstName')}
        label="Client First Name"
        fullWidth
        margin="normal"
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
      />

      <Input
        {...register('lastName')}
        label="Client Last Name"
        fullWidth
        margin="normal"
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
      />

      <Input
        {...register('consultantName')}
        label="Consultant Name"
        fullWidth
        margin="normal"
        error={!!errors.consultantName}
        helperText={errors.consultantName?.message}
      />

      <Input
        {...register('clientBackground')}
        label="Client Background"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        error={!!errors.clientBackground}
        helperText={errors.clientBackground?.message}
      />

      <Input
        {...register('additionalComments')}
        label="Closing / Additional Comments"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        error={!!errors.additionalComments}
        helperText={errors.additionalComments?.message}
      />

      <NavButtons />
    </Box>
  );
};

export default Step3ClientDetails;
