import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import PrimarySelect from '../../../ui/forms/fields/select';
import { useTheme } from '@mui/material/styles';

const Step1SelectProduct = ({ onNext, onBack, onReset }) => {
  const theme = useTheme();
  const { guidanceVehicles, loading, error } = useSelector((state) => state.vehicle);

  const validationSchema = yup.object().shape({
    vehicle: yup.string().required('Please select a vehicle.')
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vehicle: ''
    }
  });

  const onSubmit = (data) => {
    onNext(data);
  };

  const NavButtons = () => (
    <Box
      sx={{
        position: 'sticky',
        bottom: 60,
        display: 'flex',
        alignItems: 'center',
        m: 2,
        mb: 4,
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        zIndex: 1000
      }}>
      <Button onClick={onBack}>Back</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button onClick={onReset}>Start Over</Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button
        onClick={handleSubmit(onSubmit)}
        sx={{
          mr: 1,
          '&.Mui-disabled': {
            background: '#CC6323',
            color: '#fff'
          }
        }}>
        Next
      </Button>
    </Box>
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Box display="flex" justifyContent="center" mb={3} mt={3}>
        <Typography variant="h6" mb={'auto'} mr={2} mt="auto">
          I would like to...
        </Typography>

        <PrimarySelect
          name="vehicle"
          control={control}
          error={!!errors.vehicle}
          helperText={errors?.vehicle?.message}
          sx={{
            backgroundColor: '#f5f4f2',
            width: '30%',
            borderRadius: '30px',
            boxShadow:
              theme.palette.mode === 'light'
                ? '3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.7)'
                : 'none',
            background: theme.palette.background.paper,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none'
              },
              '&:hover fieldset': {
                border: 'none'
              },
              '&.Mui-focused fieldset': {
                border: 'none'
              }
            }
          }}>
          <MenuItem value="">
            <em>Select an option</em>
          </MenuItem>
          {loading ? (
            <MenuItem disabled>Loading...</MenuItem>
          ) : (
            guidanceVehicles.map((vehicle) => (
              <MenuItem key={vehicle.name} value={vehicle.name}>
                {`invest in a ${vehicle.name.toLowerCase()}...`}
              </MenuItem>
            ))
          )}
        </PrimarySelect>
      </Box>
      <NavButtons />
    </Box>
  );
};

export default Step1SelectProduct;
