import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';

import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import PrimaryButton from '../../../../components/ui/buttons/primaryButton';
import Form from '../../../../components/ui/forms';
import Checkbox from '../../../../components/ui/forms/fields/checkbox';
import CurrencyInput from '../../../../components/ui/forms/fields/currencyInput';
import RadioGroup from '../../../../components/ui/forms/fields/radioGroup';
import Select from '../../../../components/ui/forms/fields/select';

import InfoIcon from '@mui/icons-material/InfoOutlined';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Collapse,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ErrorMessage } from '@hookform/error-message';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import PDFView from './pdfView';

import CloseIcon from '@mui/icons-material/Close';

import { useNavigate } from 'react-router-dom';
import { selectGuidanceToolData } from '../../../../features/guidance/guidanceToolData.slice';
import { fundFilterCalculation } from '../../../utils/calculations';
import { numberToCurrency } from '../../../utils/currency';
const useStyles = makeStyles((theme) => ({}));

const Row = (props) => {
  const { fund, amounts, isSelected } = props;
  const [selected, setSelected] = useState(false);
  const [openDO, setOpenDO] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const funds = useSelector((state) => state.fund.guidanceFunds);

  const validationSchema = yup.object().shape(
    {
      selected: yup.bool(),
      lumpsum: yup.number().when('monthly', {
        is: (monthly) => !monthly || monthly === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            (fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum) - 1,
            `Lump Sum must be more than ${numberToCurrency(
              fund.minInvestment ? fund.minInvestment : props.minimums?.lumpsum,
              0
            )} once off`
          )
          .typeError('Lump Sum must be a number'),
        otherwise: yup
          .number()
          .typeError('Lump Sum must be a number')
          .when('lumpsum', {
            is: (lumpsum) => lumpsum > 0,
            then: yup.number().typeError('Lump Sum must be a number')
          })
      }),
      monthly: yup.number().when('lumpsum', {
        is: (lumpsum) => !lumpsum || lumpsum === 0,
        then: yup
          .number()
          .required('Either a Lump Sum or a Monthly Contribution is required')
          .moreThan(
            props.minimums?.monthly - 1,
            `Monthly Contribution must be more than ${numberToCurrency(
              props.minimums?.monthly,
              0
            )} p/m`
          )
          .typeError('Monthly Contribution must be a number'),
        otherwise: yup
          .number()
          .typeError('Monthly Contribution must be a number')
          .when('monthly', {
            is: (monthly) => monthly !== 0,
            then: yup
              .number()
              .typeError('Monthly Contribution must be a number')
              .moreThan(
                props.minimums?.monthly - 1,
                `Monthly Contribution must be more than ${numberToCurrency(
                  props.minimums?.monthly,
                  0
                )} p/m`
              )
          })
      })
    },
    ['lumpsum', 'monthly']
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { monthly: 0 },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });

  const cbSelectedWatch = watch('selected');
  useEffect(() => {
    onChange();
  }, [cbSelectedWatch]);

  useEffect(() => {
    if (isSelected) {
      setValue('selected', true);
    }
  }, []);

  const onChange = async () => {
    if (await trigger(['selected', 'lumpsum', 'monthly'])) handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    props.onChange(data);
  };

  const generateSplitRecord = (splitFunds) => {
    let s_result = [];
    for (let i = 0; i < splitFunds.length; i++) {
      const f = funds[funds.findIndex((f) => f._id === splitFunds[i].id)];
      s_result.push(f);
    }

    return (
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        role="checkbox"
        aria-checked={selected}
        tabIndex={-1}
        selected={selected}>
        <TableCell>
          <Checkbox
            name="selected"
            color="primary"
            label=""
            control={control}
            onChange={onChange}
          />
        </TableCell>
        <TableCell>
          {s_result.map((s_fund, index) => (
            <Typography key={index} sx={{ pt: 1, pb: 1 }}>
              50%
            </Typography>
          ))}
        </TableCell>
        <TableCell>
          {s_result.map((s_fund, index) => (
            <Typography key={index} sx={{ pt: 1, pb: 1 }}>
              {s_fund.name}
            </Typography>
          ))}
        </TableCell>
        <TableCell>
          {s_result.map((s_fund, index) =>
            s_fund.onePager && !mobile ? (
              <PDFView
                key={index}
                file={s_fund.onePager}
                title="One Pager"
                buttonType={'Icon'}
                icon={InfoIcon}
              />
            ) : s_fund.onePager && mobile ? (
              <Box key={index}>
                <Link href={s_fund.onePager} target="_blank">
                  <InfoIcon color="primary" />
                </Link>
              </Box>
            ) : null
          )}
        </TableCell>
        {!mobile && (
          <>
            <TableCell>
              {s_result.map((s_fund, index) => (
                <Typography key={index} sx={{ pt: 1, pb: 1 }}>
                  {s_fund.time}
                </Typography>
              ))}
            </TableCell>
            <TableCell>
              {s_result.map((s_fund, index) => (
                <Typography key={index} sx={{ pt: 1, pb: 1 }}>
                  {s_fund.assetHoldings}
                </Typography>
              ))}
            </TableCell>
            <TableCell>
              {s_result.map((s_fund, index) => (
                <Typography key={index} sx={{ pt: 1, pb: 1 }}>
                  {s_fund.regionalHoldings}
                </Typography>
              ))}
            </TableCell>
          </>
        )}
      </TableRow>
    );
  };

  return (
    <Fragment>
      {fund.split ? (
        generateSplitRecord(fund.split)
      ) : (
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          // onClick={(event) => handleClick(event, fund)}
          role="checkbox"
          aria-checked={selected}
          tabIndex={-1}
          selected={selected}>
          <TableCell>
            <Checkbox
              name="selected"
              color="primary"
              label=""
              control={control}
              onChange={onChange}
            />
          </TableCell>
          <TableCell padding="normal">100%</TableCell>
          <TableCell>{fund.name}</TableCell>
          <TableCell padding={'normal'}>
            {fund.onePager && !mobile ? (
              <PDFView file={fund.onePager} title="One Pager" buttonType={'Icon'} icon={InfoIcon} />
            ) : fund.onePager && mobile ? (
              <Link href={fund.onePager} target="_blank">
                <InfoIcon color="primary" />
              </Link>
            ) : null}
          </TableCell>
          {!mobile && (
            <>
              <TableCell>{fund.time}</TableCell>
              <TableCell>{fund.assetHoldings}</TableCell>
              <TableCell>{fund.regionalHoldings}</TableCell>
            </>
          )}
        </TableRow>
      )}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={watch('selected')} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1, display: 'flex' }}
              flexDirection={mobile ? 'column' : 'row'}
              justifyContent={'space-evenly'}>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={amounts?.lumpsum && amounts.lumpsum > 0 ? amounts.lumpsum : 0}
                name="lumpsum"
                className={classes.input}
                onChange={onChange}
              />
              {!fund.disableDebitOrder ? (
                <CurrencyInput
                  control={control}
                  defaultValue={
                    amounts?.debitOrder && amounts.debitOrder > 0 ? amounts.debitOrder : 0
                  }
                  name="monthly"
                  className={classes.input}
                  type="number"
                  id="monthly"
                  label="Monthly Contribution"
                  symbol="R"
                  error={!!errors.monthly}
                  helperText={errors?.monthly?.message}
                  onChange={onChange}
                />
              ) : mobile ? (
                <>
                  <IconButton onClick={() => setOpenDO(true)}>
                    <InfoIcon color="primary" sx={{ mr: '8px', fontSize: '1rem' }} />
                    <Typography color="primary" variant={'h6'}>
                      Debit Order
                    </Typography>
                  </IconButton>
                  <Dialog open={openDO} onClose={() => setOpenDO(false)}>
                    <DialogTitle>
                      Debit Order
                      <IconButton
                        aria-label="close"
                        onClick={() => setOpenDO(false)}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500]
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {fund.deitOrderNote}
                        <Link
                          href="mailto:clientservices@fairtreeinvest.com"
                          sx={{ cursor: 'pointer' }}>
                          clientservices@fairtreeinvest.com
                        </Link>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <PrimaryButton variant={'outlined'} onClick={() => setOpenDO(false)}>
                        Close
                      </PrimaryButton>
                    </DialogActions>
                  </Dialog>
                </>
              ) : (
                <Typography sx={{ ml: 3, mr: 3 }}>
                  {fund.deitOrderNote}{' '}
                  <Link href="mailto:clientservices@fairtreeinvest.com" sx={{ cursor: 'pointer' }}>
                    clientservices@fairtreeinvest.com
                  </Link>
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

function HelpOffshoreInvesment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  const totalSteps = 7;
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [qualifyingFunds, setQualifyingFunds] = useState([]);
  const [qualifyingRiskProfileTypes, setQualifyingRiskProfileTypes] = useState([]);

  const funds = useSelector((state) =>
    state.fund.guidanceFunds.filter((v) => v.useForOffshoreHelp)
  );

  const guidanceData = useSelector(selectGuidanceToolData);

  const { AchieveByList, GoalsList, NeedToWithdrawList, RiskDescriptionsList, RiskProfileTypes } =
    guidanceData || {};

  const validationSchema = yup.object().shape(
    {
      currency: yup.string().required('Please select the currency you are looking to invest in?'),
      investmentGoal: yup.string().required('Please select a goal.'),
      achieveBy: yup.string().required('When do you want to achieve this goal.'),
      lumpsum: yup
        .number()
        .default(0)
        .when('debitOrder', {
          is: (debitOrder) => !debitOrder || debitOrder === 0,
          then: yup
            .number()
            .required('Either a Lump Sum or a Monthly Contribution is required')
            .moreThan(
              props.minimums?.lumpsum - 1,
              `Lump Sum must be more than ${numberToCurrency(props.minimums?.lumpsum, 0)} once off`
            )
            .typeError('Lump Sum must be a number'),
          otherwise: yup
            .number()
            .typeError('Lump Sum must be a number')
            .when('lumpsum', {
              is: (lumpsum) => lumpsum > 0,
              then: yup.number().typeError('Lump Sum must be a number')
            })
        }),
      debitOrder: yup
        .number()
        .default(0)
        .when('lumpsum', {
          is: (lumpsum) => !lumpsum || lumpsum === 0,
          then: yup
            .number()
            .required('Either a Lump Sum or a Monthly Contribution is required')
            .moreThan(
              props.minimums?.monthly - 1,
              `Monthly Contribution must be more than ${numberToCurrency(
                props.minimums?.monthly,
                0
              )} p/m`
            )
            .typeError('Monthly Contribution must be a number'),
          otherwise: yup
            .number()
            .typeError('Monthly Contribution must be a number')
            .when('debitOrder', {
              is: (debitOrder) => debitOrder !== 0,
              then: yup
                .number()
                .typeError('Monthly Contribution must be a number')
                .moreThan(
                  props.minimums?.monthly - 1,
                  `Monthly Contribution must be more than ${numberToCurrency(
                    props.minimums?.monthly,
                    0
                  )} p/m`
                )
            })
        }),
      withdrawNeed: yup.string().required('Please select one.'),
      riskDesc: yup.string().required('Please select one.'),
      riskRelated: yup.string().required('Please select the one you relate the most to.'),
      riskRelatedScore: yup.number(),
      funds: yup.array().min(1, 'Please select at least one fund.'),
      acknowledge: yup
        .boolean()
        .default(false)
        .oneOf([true], 'Please acknowledge disclaimer to continue.')
    },
    ['lumpsum', 'debitOrder']
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      lumpsum: 0,
      debitOrder: 0
    },
    mode: ['all'],
    resolver: yupResolver(validationSchema)
  });
  useEffect(() => {
    register('riskRelatedScore');
    register('funds');
    register('acknowledge');
  }, []);

  useEffect(() => {
    setValue('funds', selectedFunds, { shouldValidate: true });
  }, [selectedFunds, setValue]);

  const watchScore = Math.round(watch('riskRelatedScore') * 100) / 100;
  const watchLumpsum = watch('lumpsum');
  const watchCurrency = watch('currency');

  useEffect(() => {
    const lumpsumValue = getValues('lumpsum');

    const f_funds = funds
      .filter((f) => {
        if (
          f.useForOffshoreHelp.minPoints <= watchScore &&
          f.useForOffshoreHelp.maxPoints >= watchScore
        ) {
          if (f.minInvestment && f.minInvestment <= lumpsumValue) {
            return true;
          } else if (!f.minInvestment) {
            return true;
          }
        }
        return false;
      })
      .filter((f) => {
        if (watchCurrency === 'zar') {
          return f.useForOffshoreHelp.internal === true;
        } else if (watchCurrency === 'usd') {
          return f.useForOffshoreHelp.internal === false;
        }
        return true;
      });

    setQualifyingFunds(f_funds);
  }, [watchScore, watchLumpsum, watchCurrency]);

  useEffect(() => {
    const updatedRiskProfileTypes = {
      ...RiskProfileTypes,
      types: []
    };

    if (watchCurrency === 'usd') {
      updatedRiskProfileTypes.types = RiskProfileTypes.types.filter((type) => type.points >= 3);
    } else if (watchCurrency === 'zar') {
      updatedRiskProfileTypes.types = RiskProfileTypes.types.filter((type) => type.points >= 4);
    }

    setQualifyingRiskProfileTypes(updatedRiskProfileTypes);
  }, [watchCurrency]);

  const calculate = () => {
    let achieveByValues = {};
    let withdrawValues = {};
    let riskValues = {};
    let riskProfileValues = {};

    const currentValues = getValues();

    if (currentValues.achieveBy && currentValues.achieveBy !== '') {
      achieveByValues.weight = AchieveByList.weight;
      achieveByValues.points = AchieveByList.types.find(
        (x) => x.desc === currentValues.achieveBy
      ).points;
    }

    if (currentValues.withdrawNeed && currentValues.withdrawNeed !== '') {
      withdrawValues.weight = NeedToWithdrawList.weight;
      withdrawValues.points = NeedToWithdrawList.types.find(
        (x) => x.desc === currentValues.withdrawNeed
      ).points;
    }

    if (currentValues.riskDesc && currentValues.riskDesc !== '') {
      riskValues.weight = RiskDescriptionsList.weight;
      riskValues.points = RiskDescriptionsList.types.find(
        (x) => x.desc === currentValues.riskDesc
      ).points;
    }

    if (currentValues.riskRelated && currentValues.riskRelated !== '') {
      riskProfileValues.weight = RiskProfileTypes.weight;
      riskProfileValues.points = RiskProfileTypes.types.find(
        (x) => x.name === currentValues.riskRelated
      ).points;
    }

    if (
      achieveByValues.weight &&
      withdrawValues.weight &&
      riskValues.weight &&
      riskProfileValues.weight
    ) {
      const result = fundFilterCalculation(
        achieveByValues,
        withdrawValues,
        riskValues,
        riskProfileValues
      );

      setValue('riskRelatedScore', result, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const handleNext = async (validate) => {
    const isValid = await trigger(validate);
    const data = await getValues();
    const submit = isLastStep() ? 'submit' : 'continue';

    if (isLastStep() && isValid) {
      handleDisclaimer();
    } else {
      if (isValid) {
        calculate();

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.onHelpNext({
          ...data,
          lastActiveStep: activeStep,
          formSlug: 'offshore_invest_help'
        });
        clearErrors();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFundSelect = (data, fund) => {
    let currentFunds = [...selectedFunds];

    if (!data.selected) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);
      currentFunds.splice(fundIndex, 1);
    }

    if (data.selected && currentFunds) {
      const fundIndex = currentFunds.findIndex((f) => f.fund._id === fund._id);

      if (fundIndex !== -1) {
        currentFunds[fundIndex].lumpsum = data.lumpsum;
        currentFunds[fundIndex].monthly = data.monthly;
      } else {
        currentFunds.push({
          fund,
          lumpsum: data.lumpsum,
          monthly: data.monthly
        });
      }
    }

    setSelectedFunds(currentFunds);
  };

  const onSubmit = (data) => {
    data.formSlug = 'offshore_invest_help';
    props.onDataSubmit({ ...data, lastActiveStep: activeStep });
    setOpen(false);
  };

  const getAmounts = () => {
    const amounts = {
      lumpsum: getValues('lumpsum'),
      debitOrder: getValues('debitOrder')
    };

    return amounts;
  };

  const NavButtons = ({ index, totalSteps, validate }) => {
    return (
      <Box
        sx={{
          position: 'sticky',
          bottom: 100,
          display: 'flex',
          alignItems: 'center',
          m: 2,
          mb: 4,
          justifyContent: 'flex-end',
          backgroundColor: theme.palette.background.default,
          zIndex: 1000
        }}
        display="flex"
        justifyContent={'flex-end'}>
        <PrimaryButton disabled={index === 1} onClick={handleStartOver} sx={{ mt: 1, mr: 1 }}>
          Start Over
        </PrimaryButton>
        <PrimaryButton disabled={index === 1} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </PrimaryButton>
        <PrimaryButton
          disabled={qualifyingFunds.length <= 0 && isLastStep()}
          onClick={() => handleNext(validate)}
          sx={{ mt: 1, mr: 1 }}>
          {index === totalSteps ? 'Submit' : 'Continue'}
        </PrimaryButton>
      </Box>
    );
  };

  const handleStartOver = () => {
    setConfirmResetOpen(true);
  };

  const handleCloseReset = () => {
    setConfirmResetOpen(false);
  };

  const handleConfirmReset = () => {
    reset();
    setActiveStep(0);
    setConfirmResetOpen(false);
  };

  const handleDisclaimer = () => {
    setOpenDisclaimer(true);
  };

  const closeDisclaimer = async () => {
    const isValid = await trigger('acknowledge');

    setOpenDisclaimer(false);
  };

  const acknowledgeDisclaimer = () => {
    setValue('acknowledge', true, { shouldValidate: true });

    handleSubmit(onSubmit)();
    setOpenDisclaimer(false);
  };

  return (
    <Fragment>
      <Form>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Goals and Achievement</StepLabel>
            <StepContent>
              <Select
                name={'investmentGoal'}
                control={control}
                error={!!errors.investmentGoal}
                helperText={errors.investmentGoal?.message}
                label="What is your investment goal?">
                {GoalsList.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}

                <MenuItem value={'other'}>Other</MenuItem>
              </Select>
              <Select
                name={'achieveBy'}
                control={control}
                error={!!errors.achieveBy}
                helperText={errors.achieveBy?.message}
                label="When do you want to achieve this goal by?">
                {AchieveByList.types.map((item, index) => (
                  <MenuItem value={item.desc} key={index}>
                    {item.desc}
                  </MenuItem>
                ))}
              </Select>
              <NavButtons index={1} totalSteps={7} validate={['goal', 'achieveBy']} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>How much are you looking to invest?</StepLabel>
            <StepContent>
              <CurrencyInput
                type="number"
                id="lumpsum"
                label="Lump Sum"
                symbol="R"
                error={!!errors.lumpsum}
                helperText={errors?.lumpsum?.message}
                control={control}
                defaultValue={0}
                name="lumpsum"
                className={classes.input}
              />
              <CurrencyInput
                control={control}
                defaultValue={0}
                name="debitOrder"
                className={classes.input}
                type="number"
                id="debitOrder"
                label="Debit Order"
                symbol="R"
                error={!!errors.debitOrder}
                helperText={errors?.debitOrder?.message}
              />
              <NavButtons index={2} totalSteps={7} validate={['lumpsum', 'debitOrder']} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Currency</StepLabel>
            <StepContent>
              <Select
                name={'currency'}
                control={control}
                error={!!errors.currency}
                helperText={errors.currency?.message}
                label="Which currency are you looking to invest in?">
                <MenuItem value="usd">USD </MenuItem>
                <MenuItem value="zar">ZAR</MenuItem>
              </Select>
              <NavButtons index={3} totalSteps={7} validate={'currency'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your need to withdraw</StepLabel>
            <StepContent>
              <Typography>
                Will you be able to stay invested throughout the period you selected above?
              </Typography>
              <RadioGroup
                control={control}
                aria-label="withdrawNeed"
                name="withdrawNeed"
                error={!!errors?.withdrawNeed}
                helperText={errors?.withdrawNeed?.message}
                xs={{ margin: 1 }}>
                {NeedToWithdrawList.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                    sx={mobile ? { mt: 1, mb: 1 } : {}}
                  />
                ))}
              </RadioGroup>
              <NavButtons index={4} totalSteps={7} validate={'withdrawNeed'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Risk</StepLabel>
            <StepContent>
              <Typography>RISK EXPLANATION WHEN INVESTING:</Typography>
              <Typography>
                Risk can be defined as the possibility of something not turning out the way you
                would have liked or differently to how you previously supposed. The 'risk-return
                trade-off' means: the higher the investment's level of risk, the higher the
                potential return and vice versa. This could mean potentially losing some of your
                initial capital invested, but could also mean greater potential for growth (as your
                investment value can move up or down). The degree to how much it fluctuates is
                dependent on the asset classes you invest in. When looking at your investment
                options, it's important to consider how much time you have to invest, your unique
                financial situation and how much risk you're willing to take on.
              </Typography>
              <Typography>
                When you think of 'risk', what word best describes it for you?
              </Typography>
              <RadioGroup
                control={control}
                aria-label="riskDesc"
                name="riskDesc"
                error={!!errors?.riskDesc}
                helperText={errors?.riskDesc?.message}
                xs={{ margin: 1 }}>
                {RiskDescriptionsList.types.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.desc}
                    control={<Radio />}
                    color="primary"
                    label={item.desc}
                  />
                ))}
              </RadioGroup>
              <NavButtons index={5} totalSteps={7} validate={'riskDesc'} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Investor Profile</StepLabel>
            <StepContent>
              <RadioGroup
                control={control}
                aria-label="riskRelated"
                name="riskRelated"
                error={!!errors?.riskRelated}
                helperText={errors?.riskRelated?.message}
                xs={{ margin: 1 }}>
                {!mobile ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Withdrawing</TableCell>
                        <TableCell>Total Savings</TableCell>
                        <TableCell>Investment Goal</TableCell>
                        <TableCell>Risk Appetite</TableCell>
                        <TableCell>Portfolio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {qualifyingRiskProfileTypes &&
                        Array.isArray(qualifyingRiskProfileTypes.types) &&
                        qualifyingRiskProfileTypes.types.map((item, key) => (
                          <TableRow key={key}>
                            <TableCell width="200px">
                              <FormControlLabel
                                value={item.name}
                                control={<Radio />}
                                color="primary"
                                label={item.name}
                              />
                            </TableCell>
                            <TableCell>{item.withdrawing}</TableCell>
                            <TableCell>{item.totalSavings}</TableCell>
                            <TableCell>{item.investmentGoal}</TableCell>
                            <TableCell>{item.riskAppetite}</TableCell>
                            <TableCell>{item.portfolio}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : (
                  qualifyingRiskProfileTypes &&
                  Array.isArray(qualifyingRiskProfileTypes.types) &&
                  qualifyingRiskProfileTypes.types.map((item, key) => (
                    <FormControlLabel
                      value={item.name}
                      control={<Radio />}
                      color="primary"
                      label={item.name}
                    />
                  ))
                )}
              </RadioGroup>
              <NavButtons index={6} totalSteps={7} validate={'riskRelated'} />
            </StepContent>
          </Step>

          <Step sx={{ p: 0 }}>
            <StepLabel>Fund Selection </StepLabel>
            <StepContent>
              {qualifyingFunds.length > 0 ? (
                <>
                  <Table size="small" padding={mobile ? 'none' : 'normal'} sx={{ mt: 1, mb: 2 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{''}</TableCell>
                        <TableCell padding="normal">
                          <Typography variant="subtitle2">%</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Investment Solution</Typography>
                        </TableCell>
                        <TableCell>{''}</TableCell>
                        {!mobile && (
                          <>
                            <TableCell>
                              <Typography variant="subtitle2">Time</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Asset Holdings</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">Regional Holdings</Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {qualifyingFunds.map((fund, index) => (
                        <Row
                          key={index}
                          fund={fund}
                          onChange={(data) => handleFundSelect(data, fund)}
                          minimums={props.minimums || null}
                          amounts={getAmounts()}
                          isSelected={selectedFunds.some(
                            (item) => item.fund.name === fund.name && item.fund._id === fund._id
                          )}
                        />
                      ))}
                    </TableBody>
                  </Table>
                  <Box>
                    Score:{''}
                    {watchScore}
                  </Box>
                </>
              ) : (
                <Typography variant="h4">
                  We do not have any funds or investment solutions available that match your
                  investment objectives. Please consider revising your investment time horizon or
                  investment risk profile
                </Typography>
              )}
              <Box>
                {isLastStep() && qualifyingFunds.length > 0 && (
                  <ErrorMessage
                    errors={errors}
                    name="funds"
                    render={({ message }) => <Typography color="error">{message}</Typography>}
                  />
                )}
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <ErrorMessage
                  errors={errors}
                  name="acknowledge"
                  render={({ message }) => <Typography color="error">{message}</Typography>}
                />
              </Box>
              <NavButtons index={7} totalSteps={7} validate={'funds'} />
            </StepContent>
          </Step>
        </Stepper>
      </Form>

      <Dialog open={openDisclaimer} onClose={closeDisclaimer}>
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            I acknowledge that this tool is a factual filter of published information relevant to
            each investment solution's fact sheet. It does not make a recommendation or render
            advice to an investor, but merely provides factual information. For advice on the
            suitability of these products to the investor's financial goals and objectives, we
            strongly recommend consulting an approved financial advisor. Please note the disclosures
            relevant to each product displayed on this page as published on their fact sheets.
            Fairtree Asset Management is an authorised financial services provider (FSP 25917).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={closeDisclaimer} color="primary">
            Close
          </PrimaryButton>
          <PrimaryButton onClick={acknowledgeDisclaimer} color="primary">
            Acknowledge
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmResetOpen}
        onClose={handleCloseReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Start Over?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to start over?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset}>Cancel</Button>
          <Button onClick={handleConfirmReset} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default HelpOffshoreInvesment;
